.awards-page-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 2rem auto;
  justify-content: center;


}
.awards-container ul, .awards-container li {
    list-style: none;
    padding: 0;
  }
  
  .awards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    /* background: linear-gradient(45deg, #209cff, #68e0cf); */
    /* padding: 3rem 0; */
  }
  
  .awards-container .wrapper {
    /* background: #eaf6ff; */
    background: rgba(0, 98, 155, 1);
    padding: 2rem;
    border-radius: 15px;
    min-width: 345px;
    /* width: 80%; */
    /* width: max(80%, 345px); */
  }
  
  .awards-container h1 {
    /* font-size: 1.1rem; */
    font-size: clamp(2rem, 2.5vw, 5rem);
    font-family: sans-serif;
    color: #fff;
    font-weight: 700;
    margin-top:2rem;
  }

  
  .awards-container .sessions {
    margin-top: 2rem;
    border-radius: 12px;
    position: relative;
  }
  
  .awards-container li {
    padding-bottom: 1.5rem;
    /* border-left: 1px solid #abaaed; */
    border-left: 1px solid 		#FFFFFF;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
  }
  .awards-container li:last-child {
    border: 0px;
    padding-bottom: 0;
  }
  .awards-container li:before {
    content: "";
    width: 15px;
    height: 15px;
    background: #fff;
    /* border: 1px solid #4e5ed3;
    box-shadow: 3px 3px 0px #bab5f8;
    box-shadow: 3px 3px 0px #bab5f8; */
    border: 1px solid 		#FFA300	;
    box-shadow: 3px 3px 0px 		#FFA300	;
    box-shadow: 3px 3px 0px 		#FFA300	;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 0px;
  }
  .awards-container p {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 900;
  }

  .awards-container .time {
    /* color: #2a2839; */
    /* color: #fff; */
    color: 	#FFA300;
    /* font-size: 1.5rem; */
    font-weight: 700;
  }

  @media screen and (min-width: 601px) {
    .awards-container .time {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 600px) {
    .awards-container.time {
      margin-bottom: 0.3rem;
      /* font-size: 0.85rem; */
    }
  }
  
/* .awards-container {
  color: #4f4f4f;
  font-family: sans-serif;
  line-height: 1.5;
  margin-top: 0.4rem;
} */
/* @media screen and (max-width: 600px) {
  .awards-container p {
    font-size: 1.4rem;
  }
} */