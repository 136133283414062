/*===== GOOGLE FONTS =====*/

/*===== VARIABLES CSS =====*/
:root {
    --dark-color-lighten: #f2f5ff;
    --red-card: #a62121;
    --blue-card: #4bb7e6;
    --btn: #141414;
    --btn-hover: #3a3a3a;
    --text: #fbf7f7;
  }
  
  .chapter-card a {
    display: inline-block;
    border: none;
    outline: none;
    border-radius: 0.2rem;
    color: var(--text);
    cursor: pointer;
  }
  
  .chapter-card a {
    text-decoration: none;
  }
  
  .chapter-card img {
    max-width: 100%;
    height: 100%;
    user-select: none;
  }
  
  /*===== CARD =====*/
  .chapter-cards-container {
    min-height: 50vh;
    /* width: 850px; */
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 3.8rem;
    column-gap: 2rem;
    margin-top: 5rem;
  }
  .chapter-cards-container .chapter-card {
    position: relative;
    padding: 1rem;
    width: 300px;
    height: 400px;
    box-shadow: -1px 15px 30px -12px rgb(32, 32, 32);
    border-radius: 0.9rem;
    background-color: var(--red-card);
    color: var(--text);
    cursor: pointer;
  }
  
  .chapter-card .card-blue {
    background: var(--blue-card);
  }
  
  .chapter-card .product-image {
    height: 230px;
    width: 100%;
    /* transform: translate(0, -1.5rem); */
    transform: translate(1rem, -1.5rem);
    transition: transform 500ms ease-in-out;
    filter: drop-shadow(5px 10px 15px rgba(8, 9, 13, 0.4));
  }
  .chapter-card .product-image img{
    margin: 0 auto;
  }

  .chapter-card .product-info {
    text-align: center;
  }
  
  .chapter-card:hover .product-image {
    transform: translate(-1rem, -5rem) rotate(-15deg);
  }
  
  .chapter-card .product-info h2 {
    font-size: 1.4rem;
    font-weight: 900;
  }
  .chapter-card .product-info p {
    margin: 0.4rem;
    font-size: 0.8rem;
    font-weight: 900;
  }
  .chapter-card .price {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .chapter-card .btn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0.8rem;
  }
  .chapter-card .buy-btn {
    background-color: var(--btn);
    padding: 0.6rem 3.5rem;
    font-weight: 600;
    font-size: 1rem;
    transition: 300ms ease;
  }
  .chapter-card .buy-btn:hover {
    background-color: var(--btn-hover);
  }
  .chapter-card .fav {
    box-sizing: border-box;
    background: #fff;
    padding: 0.5rem 0.5rem;
    border: 1px solid#000;
    display: grid;
    place-items: center;
  }
  
  .chapter-card .svg {
    height: 25px;
    width: 25px;
    fill: #fff;
    transition: all 500ms ease;
  }
  
  .chapter-card .fav:hover .svg {
    fill: #000;
  }
  
  @media screen and (max-width: 800px) {
    .chapter-cards-container {
      padding: 2rem 0;
      width: 100%;
      flex-direction: column;
      gap: 3.8rem;
    }
  }
  