 .people-row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
 }
 .col-12 {
     width: 300px;
 }
 .our-team {
    padding: 30px 0 40px;
    margin-bottom: 30px;
    /* background-color: #f7f5ec; */
    background-color: rgba(255, 209, 0, 0.2);
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    /* border: 1px solid #eee; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
    box-shadow: 8px 8px var(--ieee-blue);
  }
  .our-team:hover {
    transition: all .4s ease-in-out;
    box-shadow: 2px 2px var(--ieee-blue);
    transform: translateX(4px) translateY(4px);
  }
  
  .our-team .picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
  }
  
  .our-team .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    /* background-color: #1369ce; */
    background-color: var(--ieee-blue);
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
  }
  
  .our-team:hover .picture::before {
    height: 100%;
  }
  
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* background-color: #1369ce; */
    background-color: var(--ieee-blue);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 100%;
    /* height: auto; */
    height: 130px;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
    object-fit: cover;
  }
  
  .our-team:hover .picture img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.7);
  }
  
  .our-team .title {
    display: block;
    font-size: 15px;
    color: #4e5052;
    text-transform: capitalize;
  }
  
  .our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    /* background-color: #1369ce; */
    background-color: var(--ieee-blue);
    position: absolute;
    bottom: 0;
    /* bottom: -100px; */
    left: 0;
    transition: all 0.5s ease 0s;
  }
  
  .our-team:hover .social {
    bottom: 0;
  }
  
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    /* padding: 10px; */
    /* font-size: 17px; */
    padding: 5px 10px;
    color: #fff;
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .our-team .social li a svg {
    width: 21px;
  }
  
  .our-team .social li a:hover {
    /* color: #1369ce; */
    color: var(--ieee-blue);
    background-color: #f7f5ec;
  }
  