.email-reminder {
    width: 100%;
    position: absolute;
    top:0;
    background-color:	#E87722;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 0.7rem;
}
.email-reminder h4 {
    color: #fff;
}
.email-reminder .inline {
    display: flex;
    margin-top:7px;
}
.email-reminder input[type='email'] {
    -webkit-appearance: none;
    padding: 5px 10px 5px 3px;
    width: 60%;
    border: 1px solid #e8eaed;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
}
.email-reminder input[type='email']:focus {
    outline: none;
    border-color: #07f;
    box-shadow: 0 0 0 2px rgba(0,119,255,0.2);
}
.email-reminder input[type='email']::placeholder {
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
}

.email-reminder button {
    background-color: #00629B;
    border: 1px solid #eee;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #fff;
    font-size: .875rem;
    font-weight: 650;
    /* line-height: 1.25rem; */
    padding: .3rem 0.5rem;
    text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    outline: 2px solid transparent;
}

.email-reminder button:hover {
    /* background-color: rgb(249,250,251); */
      color: #00629B;
      /* color: 	#981D97; */
      background-color: #fff;
      transition: 150ms ease-in;
  }
  
  .email-reminder button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  
  .email-reminder button:focus-visible {
    box-shadow: none;
  }
  .event-chapters {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
  }
  .event-card-details i {
    margin-top:-3px;
  }
  .event-card-details svg {
    margin-top: -3px;
  }
  .event-chapters-list {
    list-style: none;
    display: flex;
    column-gap: 0.5rem;
  }
  .event-chapters-list li {
    list-style-type: none;
    align-items: center;
  }
  .event-chapters-list li a {
    text-decoration: none;
    color:#222;
    transition: 100ms ease-in;

  }
  .event-card-buttons .event-page-button {
    font-family: var(--normal-font);
  }