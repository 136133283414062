/* .chapter-container {
    width: max(90%, 320px);
    border: 2px solid #eee;
    margin-top: 5rem;
    margin: 5rem auto 0 auto;
    padding: 2rem 0.5rem;
} */
.chapter-top-hero {
    height: 100vh;
    height: 100vh;
    /* height: 100dvh; */
    position: relative;
    /* background-color: #00629B; */
    /* background-blend-mode: multiply; */
    animation: fadeIn 0.25s ease-in-out forwards;

}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (prefers-reduced-motion: reduce) {
    /* CSS to disable motion goes here */
    .chapter-top-hero  {
      animation: none;
    }
  }

/* .chapter-bg-color {
    background-color: #00629B;
    height: 100%;
    width: 100%;
    background-blend-mode: multiply;
    position: absolute;
    top:0;
    opacity: 0.6;
} */
.chapter-bg-image {
    /* background-color: rgba(200,100,0,.5); */
    background-color: rgba(0, 98, 155, 0.4);
    /* background-color: #00629B; */
    mix-blend-mode: overlay;
    /* background-image: url(../assets/images/landing/background_hero.jpg); */
    /* background-image: url(https://scontent.fath6-1.fna.fbcdn.net/v/t1.6435-9/56922702_310412946305896_3754576220214263808_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_ohc=C2P36Sq_fssAX8MaobE&tn=B1R0WiGpZ9V77Enn&_nc_ht=scontent.fath6-1.fna&oh=00_AfDDYtbzma_eTm10XA1RwwEWCDmQ9eZd36vlsGHiSypbag&oe=64270AA8); */
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-blend-mode: multiply;
}
.chapter-top-hero .chapter-top-hero-text {
    text-align: center;
    position: absolute;
    z-index: 9999;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}
.chapter-top-hero .chapter-top-hero-text h1 {
    font-size: clamp(3.7rem, 7.5vw, 9rem);
    width:100%;
    font-family: var(--spartan);
    -webkit-text-stroke: 3px #000;
    -webkit-filter: drop-shadow(calc(var(--default-padding)*-1) var(--default-padding) calc(var(--default-padding)*2) var(--black));
    filter: drop-shadow(calc(var(--default-padding)*-1) var(--default-padding) calc(var(--default-padding)*2) var(--black));
}
.chapter-top-hero .chapter-top-hero-text .get-to-know-us {
    width:fit-content;
    margin: 0 auto;
    padding: 20px 35px;
    font-size: clamp(1rem, 20px, 2rem);
    background-color: #01131d;
    color: #fff;
    background-color: #BA0C2F;
    border: 3px solid #fff;
    cursor: pointer;
    font-weight: 900;
    outline: 2px solid transparent;
    text-transform: uppercase;
    border-radius: 1.4rem;
    transition: background-color .3s ease-in-out;
}
.chapter-top-hero .chapter-top-hero-text .get-to-know-us:hover {
    background-color: #fff;
    color: #BA0C2F;
    border: 3px solid #BA0C2F;
}
.chapter-page {
    padding-top: 9rem;
}
.chapter-page .cool-title{
    position: relative;
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(8,9,13,.4));
}
/* .chapter-page .cool-title:after {
    content: "";
    width:50px;
    height:50px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    background-image: url(https://cdn-icons-png.flaticon.com/512/5766/5766858.png);
    opacity:0.5;
    top:-50px;
    right:50%;
    margin:0;
    padding:0;
}
.chapter-page .cool-title:before {
    content: "";
    width:50px;
    height:50px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    background-image: url(../assets/images/chapters/cs/cs2.svg);
    top:-50px;
    left:10px;
    margin:0;
    padding:0;
} */
.chapter-hero {
    min-height: 80vh;
}
.chapter-padding {
    padding-inline: max(5%, 0.7rem);
    /* margin-bottom: 4rem; */
}
.chapter-details {
    margin: 6rem auto 0 auto;
    font-size: clamp(1rem, 0.8em + 0.43vw, 1.7rem);
    font-weight: 550;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 3rem;
    row-gap: 3rem;
}
.chapter-details-section {
    width: 60%;
}
.chapter-details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom:4px; */
}
.chapter-social-media a {
    text-decoration-line: none;
    margin-right: 10px;
    color: #222;
}
.chapter-social-media i {
    font-size: 1.7rem;
    color: #222;
}
.chapter-social-media svg {
    font-size: 1.7rem;
    width: 1.7rem;
    margin: 0;
    padding: 0;
    transition: 150ms ease-in;
}
  
.chapter-social-media i:hover {
    color: var(--link-color);
    transition: 150ms ease-in;
}
  
.chapter-social-media a:hover {    
      color: var(--link-color);
      transition: 150ms ease-in;
}

.chapter-join-link a {
    text-decoration: none;
    background-color: var(--ieee-blue);
    color: #fff;
    padding: 0.1rem 0.3rem;
    border-radius: 4px;
    border: 1px solid #eee;
    transition: 250ms ease-in;
}
.chapter-join-link a:hover {
    text-decoration: none;
    color: var(--ieee-blue);
    background-color: #fff;
}

.chapter-details .chapter-details-text {
    /* width: 60%; */
    /* line-height: calc(1ex / 0.32); */
    background-color: #ededed;
    color: #00629B;
    font-weight: 700;
    /* line-height: 1.7rem; */
    padding: 2rem;
    border: 2px solid #353535;
    overflow: visible;
    overflow-wrap: break-word;
    box-sizing: border-box;
    border-radius: 4px;
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(8, 9, 13, 0.4));
}

.chapter-details img {
    width: max(20%, 250px);
    margin: 0 auto;
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(8, 9, 13, 0.4));

}

.chapter-subgroups {
    width: inherit;
    --mask:
          radial-gradient(65.74px at 50% 88.50px,#000 99%,#0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
          radial-gradient(65.74px at 50% -58.5px,#0000 99%,#000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
          radial-gradient(65.74px at 50% calc(100% - 88.50px),#000 99%,#0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
          radial-gradient(65.74px at 50% calc(100% + 58.50px),#0000 99%,#000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x;
        -webkit-mask: var(--mask);
        mask: var(--mask);
        background-color: 		#00629B;
        padding-inline: max(3%, 1rem);
        padding-block: min(20%,5rem);
}
.chapter-subgroups h1 {
    color: #fff;
}
.chapter-subroups-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.chapter-subroups-wrapper {
    width: 100%;
    margin-top: 2rem;
    row-gap: 2rem;
}
.chapter-subgroup {
    overflow: hidden;
    width: 15%;
    min-width: 300px;    
    background-color: #fff;
    border-radius: 4px;
    margin: 0 auto;
    filter: drop-shadow(2.5px 2.5px 2.5px rgba(8, 9, 13,.4));

}
.chapter-subgroup img {
    /* width: 100%; */
    /* min-height: 200px; */
    border-bottom: 1px solid #ccc;
    height: 170px;
    object-fit: cover;
    width: auto;
    /* max-height: 160px; */
}
.chapter-subgroup .subgroup-title {
    margin: 0 auto;
    text-align: center;
    padding: 0.5rem 0 0 0;
}
.chapter-subgroup p {
    padding: 0.5rem 0.5rem 0.1rem 0.5rem;
    line-height: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient:vertical;
    overflow: hidden;
}
.chapter-subroups-wrapper img {
    width: 100%;
}
.chapter-section {
    /* margin-top:min(5rem,350px); */
}

.chapter-events {
    margin-top:5rem;
    position: relative;
}
.chapter-section-title {
    font-size: clamp(2rem, 2.5vw, 6rem);
    color:#00629B;
    font-weight: 700;
}

.chapter-events-wrapper {
    margin-top:3rem;
}

.chapter-people {
    margin-top: 5rem;
}
.chapter-people .people-row {
    margin-top: 3rem;
}

@media screen and (max-width:1100px) {
    .chapter-details {
        column-gap:1.5rem;
    }
    .chapter-details .chapter-details-section{
        width: 65%;
    }
}

@media screen and (max-width:870px) {
    .chapter-details {
        flex-direction: column;
    }
    .chapter-details .chapter-details-section {
        width: 100%;
    }
    .chapter-details .chapter-details-text {
        padding: 1rem;
    }
}
@media screen and (max-width:550px) {
    .chapter-page {
        padding-top:5rem;
    }
}
/* .chapter-page-hero {
    display: flex;
    flex-flow: column wrap;
    display: grid;
    grid-template-areas: "nav" "main";
    grid-template-columns: 1fr;
    grid-template-rows: 5rem calc(100vh - 5rem);
    height: 100dvh;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    position: relative;
}
.chapter-bg-image {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-image: linear-gradient(to bottom, rgba(0, 181, 226, 0.35), rgba(0, 0, 0, 0.5)), url(../assets/images/chapters/cs/cs_chapter.jpg);
    background-position: center center;
    background-repeat: no-repeat;
}
.chapter-page-hero h1{
    margin: auto 0;
    text-align: center;
    bottom:0px;
    color: #fff;
    padding: 1rem 0.5rem;
    font-size:clamp(3rem, 6vw, 15rem);
} */

