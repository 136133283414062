.event-wrapper article {
    width: min(97%, 1000px);
    margin: 0 auto;
}
.event-wrapper article .event-image img {
    width: 100%;
    /* min-width: 300px; */
    /* max-height: 500px; */
    /* object-fit: cover; */
    border-radius: 4px;
}
.event-wrapper article .event-details {
    margin-top:0.5rem;
}
.event-wrapper article .event-details .event-title {
    background-color: #00629B;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    width: fit-content;
    /* font-size: clamp(1.5rem, 2.2vw, 5rem); */
    font-size: clamp(1.5rem, 2vw, 5rem);
}
.event-wrapper article .event-details {
    width: 100%;
}
.event-wrapper article .event-details .event-description .event-datetime {
    margin-top:1rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    flex-wrap: wrap;
    row-gap: 1rem;
}
.event-wrapper article .event-details .event-description .event-datetime ul li a {
    text-decoration: none;
    color:#222;
    transition: 100ms ease-in;
}
.event-datetime i{
    margin-right: 5px;
    margin-top:-3px;
}
.event-datetime div {
    display: flex;
    align-items: center;
}
.event-datetime svg {
    width: 20px;
    margin-right: 5px;
}
/* .event-wrapper article .event-details .event-description .event-datetime div{ */
    /* display:inline-block; */
/* } */
.event-datetime ul {
    display: flex;
    align-items: center;
    list-style: none;
    column-gap: 0.5rem;
    float: right;
}
.event-wrapper article .event-details .event-description .event-body {
    margin-top:2rem;
    font-size: clamp(1.2rem, 2vw, 1.4rem);
    font-weight: 500;
    line-height: 1.6rem;
    width: 100%;
    word-wrap: break-word;
    /* text-align: justify; */
}
.event-wrapper article .event-details .event-description .event-body p {
    margin-block: 1rem;
}

.event-wrapper article .event-details .event-description .event-body h1{
    margin-top:2.5rem;
    font-size: clamp(2.5rem, 2.5vw, 5rem);
    line-height: 2.3rem;
    text-align: initial;
    color:var(--ieee-blue);
}
.event-wrapper article .event-details .event-description .event-body h2 {
    margin-top:2rem;
    font-size: clamp(2rem, 2vw, 4rem);
    line-height: 2rem;
    text-align: initial;
    color:var(--ieee-blue);

}
.event-wrapper article .event-details .event-description .event-body h3 {
    margin-top:1.5rem;
    font-size: clamp(1.9rem, 1.7vw, 3rem);
    line-height: 2.1rem;
    text-align: initial;
    color:var(--ieee-blue);

}
.event-body img {
    width: 100%!important;
    height: auto!important;
    margin-block: 1rem;
}
.event-wrapper article .event-details .event-description .event-body a {
    text-decoration: none;
    color: var(--light-blue);
}
.event-wrapper article .event-details .event-description .event-body a:hover {
    color: var(--dark-blue);
    text-decoration: underline;
}
.event-wrapper article .event-details .event-description .event-body strong {
    color: var(--dark-blue);
    font-weight: 800;
}

.event-body > :is(h1,h2,h3,h4,h5,h6) {
    margin-block:1.5rem;
    line-height: 2.3rem!important;
    color: var(--ieee-blue);
}


@media screen and (min-width:700px) {
    .event-wrapper article .event-image img {
        /* max-width: 650px; */
        /* max-height: 650px;  */
        /* object-fit: cover; */
    }
}