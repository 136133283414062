/* section.landing-section {
    height: 100%;
    min-height: 100vh;
    padding: 7rem 4rem 0 4rem;
    background-color: #fff;
}
section.landing-section h1 {
    font-size: clamp(2rem, 8vw, 5rem);
    color: #fff;
    background-color: #00629B;
    padding: 0.7rem 0.5rem;
    width: fit-content;
    border-radius: 8px;
}
section.landing-section .who-are-we-description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 15rem 2rem 0 2rem;
}
section.landing-section .who-are-we-description img {
    width: 30%;
    min-width: 100px;
}
section.landing-section .who-are-we-description .who-are-we-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;
    position: relative;
}
section.landing-section .who-are-we-description .who-are-we-text h3 {
    font-size: clamp(2rem, 3vw, 4rem);
    font-weight: 700;
}
section.landing-section .who-are-we-description .who-are-we-text p {
    line-height: clamp(1rem, 1.6vw, 1.6rem);
    font-weight: 500;
    font-size: clamp(1em, 1.6vw, 1.4375em);
}
@media screen and (max-width:1230px) {
    section.landing-section .who-are-we-description {
        padding: 15rem 0.5rem 0 0.5rem;
    }
}
@media screen and (max-width:1000px) {
    section.landing-section {
        padding: 7rem 2rem 0 2rem;
    }
}


@media screen and (max-width:932px) {
    section.landing-section {
        padding: 7rem 1rem 0 1rem;
    }
    section.landing-section .who-are-we-description {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6rem 1rem 0 1rem;
    }
    section.landing-section .who-are-we-description .who-are-we-text {
        width: 100%;
        margin-top: 3rem;
    }
    section.landing-section .who-are-we-description img {
        width: 60%;
        min-width: 100px;
    }
    section.landing-section .who-are-we-description .who-are-we-text h3 {
        font-size: clamp(2rem, 6vw, 4rem);
    }
    section.landing-section .who-are-we-description .who-are-we-text p {
        font-size: clamp(1em, 3.5vw, 1.4375em);
        line-height: clamp(1rem, 3.4vw, 2rem);
    }
}

@media screen and (max-width:600px) {
    section.landing-section {
        padding: 7rem 1rem 0 1rem;
    }
    section.landing-section .who-are-we-description img {
        width: 90%;
    }
    section.landing-section .who-are-we-description .who-are-we-text p {
        font-size: clamp(1em, 4vw, 1.4375em);
        margin-top:20px;
    }
}

@media screen and (max-width:400px) {
    section.landing-section .who-are-we-description .who-are-we-text h3 {
        font-size: clamp(1.7rem, 8vw, 4rem);
    }
    section.landing-section .who-are-we-description .who-are-we-text p {
        font-size: clamp(1em, 4.3vw, 1.4375em);
        line-height: 1.1rem;
        margin-top:20px;
    }
} */

section.landing-section {
    background-color: #fff;
    padding-inline: max(3%, 1rem);
    padding-block: min(5%,300px);
    min-height: 100dvh;
}

section.landing-section h1 {
    font-size: clamp(2rem, 3vw, 5rem);
    font-weight: 900;
    /* color:#00629B; */
    color:#00629B	;
}
.who-are-we {
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    margin-top: clamp(7rem, 8vh, 5rem);
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    column-gap: min(250px, 5rem);
    row-gap: min(250px, 5rem);
    width: 95%;
    margin: 0 auto;
}
.second-row {
    flex-direction: row-reverse;
}
.who-are-we .row .text{
    /* max-width: 60%; */
    font-weight: 500;
    width: max(60%, 345px);
}
.who-are-we .row .text h3 {
    font-size: clamp(1.8rem, 2vw, 3rem);
    color: #00629B;
    font-weight: 900;
}
.who-are-we .row .text p {
    margin-top: 1rem;
    font-size: clamp(1rem, 1em + 0.53vw, 2rem);
    line-height: clamp(1.5rem, 1.4vw, 2rem);
}
.who-are-we .row img{
    min-width: 100px;
    width: max(300px, 10%);
    
}
.who-are-we .second-row img{
    min-width: 100px;
    width: max(270px, 10%);
}
.metrics {
    min-height: 70svh;
    max-height: 100svh;
    width: 100%;
    /* background-image: url(https://cdn.sstatic.net/Sites/math/img/site-background-image.png?v=09a720444763); */
    background-color: #002855;
    padding-inline: max(3%, 1rem);
    padding-block: min(20%,5rem);
    --mask:
        radial-gradient(44.60px at 50% 63.00px,#000 99%,#0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
        radial-gradient(44.60px at 50% -33px,#0000 99%,#000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
        radial-gradient(44.60px at 50% calc(100% - 63.00px),#000 99%,#0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
        radial-gradient(44.60px at 50% calc(100% + 33.00px),#0000 99%,#000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    /* background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75)),url(../assets/images/landing/background_hero.jpg); */
    /* background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75)),url('https://scontent.fath6-1.fna.fbcdn.net/v/t1.6435-9/64498292_2249250108491615_154181564920496128_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=KI7wGuzaTAYAX-Zb3C0&_nc_ht=scontent.fath6-1.fna&oh=00_AfAw-YfPJWDAztcQXumS4hNOkVojJLpo-NI51A5ecM-QDg&oe=6478739F'); */
    /* background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75)),url(../assets/images/IMG_5635.jpg); */
    background-image:  linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75)),
    url(../assets/images/landing/metric_background_optimized.jpeg);
    background-position: 50% 40%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    row-gap: min(3rem, 100px);

    /* Create the parallax scrolling effect */
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.metrics h1 {
    color:#fff;
    font-size: clamp(2.7rem, 3vw, 5rem);
    font-weight: 900;
    text-align: center;
}
ul.metrics-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap:2rem;
    row-gap: 2rem;
}
li.metric {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex:1;
}
li.metric img{
    width: min(50%, 100px);
}
li.metric strong{
    font-size: clamp(1.8rem, 1.5vw, 5rem);
    /* font-weight: 700; */
    margin-bottom: 1rem;
}
li.metric .count{
    font-size: clamp(1.5rem, 2vw, 4rem);
    /* font-weight: 850; */
}
section.chapter-cards-section {
    background-color: #fff;
    padding-inline: max(3%, 1rem);
    padding-block: min(5%,300px);
}
section.chapter-cards-section h1 {
    font-size: clamp(2rem, 3vw, 5rem);
    font-weight: 850;
    color:#00629B	;
}
.executive-board-people section.people-container {
    background-color: #fff;
    padding-inline: max(3%, 1rem);
    padding-block: min(5%,300px);
    /* min-height: 40dvh; */
}

.executive-board-people section.people-container h1 {
    font-size: clamp(2rem, 3vw, 5rem);
    font-weight: 850;
    color:#00629B	;
}
.executive-board-people section.people-container .people-row {
    margin-top: clamp(7rem, 8vh, 5rem);
}


@media (prefers-reduced-motion: reduce) {
    /* CSS to disable motion goes here */
    .metrics {
      background-attachment: unset;
    }
  }

@media screen and (max-width:1020px) {
    .who-are-we .row .text {
        width: max(90%, 340px)!important;
    }
}


@media screen and (max-width: 991px) {
    /* start of large tablet styles */
    /* Create the parallax scrolling effect */
    .metrics {
        background-attachment: unset;
    }

}
