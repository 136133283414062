   footer {
      /* background-color: #2b2b2b; */
      background-color: #222;
      color: white;
      /* font-size: 1rem; */
      text-transform: none;
      /* padding-top:100px; */
      margin-top:5rem;
      line-height: 1.3rem;
      width: 100%;
  }
  
  
  footer #container div {
      min-width: 100px; 
      text-align: left;
      justify-content: center;
      /* grid-row: 1/3; */
  }
  
  footer #prom-column {
    grid-row: 1/2 !important;
  }
  
  footer #icons-column {
    /* grid-row: 2/3 !important; */
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  footer #icons-column img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  fotoer #icons-column .icon-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  
  footer #icon {
      width: 20px;
      height: 20px;
      border-radius: 25%;
      opacity: 70%;
      display: inline;
      margin: 5px;
    }
    
  footer  #circle-icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      opacity: 70%;
      display: inline;
      margin: 5px; 
    }
    
   footer #icon:hover, #circle-icon:hover {
      border: 1px solid black;
    }
  
  footer #container {
      display: grid;
      row-gap: 1.5rem;
      /* grid-template:   2fr 1fr / 0.8fr 1.2fr  1fr  1fr; */
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      /* display: flex; */
      /* flex-direction: row; */
      /* justify-content: space-around; */
      padding: 1rem;
      justify-items: center;
      /* align-items: center; */
      /* flex-wrap: wrap; */
    }
  
  footer a {
    display: inline;
    text-decoration-line: none;
    color: #fff;
  }

   footer i  {
      width: 20px;
      height: 20px;
      border-radius: 25%;
      opacity: 70%;
      display: inline;
      margin: 5px;
  }
  footer svg {
    width: 20px;
    height: 20px;
    border-radius: 25%;
    opacity: 70%;
    display: inline;
    margin: 2px;
    font-size: 1.3rem;
  }
  
   footer i {
    font-size: 1.3rem;
    color: #fefefe;
  }
  
   footer i:hover {
    color: var(--ieee-blue);
    transition: 150ms ease-in;
  }
  
  footer a:hover {    
      color: var(--ieee-blue);
      transition: 150ms ease-in;
  }
  
  footer a  #logoIEEE  {
    justify-content: center;
      align-self: center;
  }
  
  footer #logoIEEE {
    
      width: 100px;
      height: 100px;
      border-radius: 50%;
      opacity: 70%;
      display: inline;
      
  }
  
  footer i {
    display: inline;
  }
  
    
  
  
  footer p {
    padding: 1em 0em;
    text-align: center;
    
  }
  
  footer hr {
    margin: 0em 1em;
  }
  
  footer h3 {
    all: revert;
    padding: 0.2em 0em;
    margin: 0;
  }
  
  footer .footer-title{
    /* margin-left: 3vw; */
    padding: 0; 
    display: flex;
    flex-shrink: 2;
  }
  
  footer #womenEng {
    color: mediumslateblue;
  }
  @media screen and (max-width:420px) {
    footer #container {
      justify-items:normal;
    }
  }