.news-article-container {
    width: min(97%, 1000px);
    /* margin: 0 auto; */
}
.news-article-container .news-title {
    width: fit-content;
    font-size: clamp(1.9rem, 2.3vw, 5rem);
}
.news-article-container .news-article-image {
    width: 100%;
    /* min-width: 300px; */
    /* max-height: 500px; */
    object-fit: contain;
    border-radius: 4px;
    margin-top:2rem;
}

.news-article-container .news-article-details .news-time {
    margin-top:1rem;
    font-weight: 600;
    color: #fff;
    background-color: var(--ieee-blue);
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    align-items: center;
    
}
.news-article-container .news-article-details .news-time i {
    margin-right: 5px;
}
.news-article-container .news-article-details .news-time svg {
    width: 22px;
    margin-right: 3px;
}

.news-article-container .news-article-details .news-article-body {
    margin-top:2rem;
    font-size: clamp(1.2rem, 2vw, 1.4rem);
    font-weight: 500;
    line-height: 1.6rem;
    width: 100%;
    word-wrap: break-word;
}

.news-article-container .news-article-details .news-article-body p {
    margin-block: 1rem;
}

.news-article-container .news-article-details .news-article-body h1{
    margin-top:2.5rem;
    font-size: clamp(2.2rem, 2.1vw, 5rem);
    line-height: 2.3rem;
    text-align: initial;
    color: var(--ieee-blue);
}
.news-article-container .news-article-details .news-article-body h2 {
    margin-top:2rem;
    font-size: clamp(2rem, 2vw, 4rem);
    line-height: 2rem;
    text-align: initial;
    color: var(--ieee-blue);

}
.news-article-container .news-article-details .news-article-body h3 {
    margin-top:1.5rem;
    font-size: clamp(1.9rem, 1.7vw, 3rem);
    line-height: 2.1rem;
    text-align: initial;
    color: var(--ieee-blue);

}
.news-article-container .news-article-details .news-article-body a {
    text-decoration: none;
    color: var(--light-blue);
}
.news-article-container .news-article-details .news-article-body a:hover {
    color: var(--dark-blue);
    text-decoration: underline;
}
.news-article-container .news-article-details .news-article-body strong {
    color: var(--dark-blue);
    font-weight: 800;
}
.news-article-body img {
    width: 100%!important;
    height: auto!important;
    margin-block:1rem;
    border-radius: 4px;
}

.news-article-container .news-article-details .news-article-body > :is(h1,h2,h3,h4,h5,h6) {
    margin-block:1.5rem;
    line-height: 2.3rem!important;
    color: var(--ieee-blue);
}