.news-card {
    display: flex;
    /* justify-content: space-between; */
    width: 90%;
    /* width: 70%; */
    margin: 0 auto;
    column-gap: 2rem;
    border: 1px solid #ccc;
    outline: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0,0,0,.08);
}
.news-card img {
    min-width: 35%;
    object-fit: cover;
    min-height: 100%;
    max-width: 35%;
    /* max-height: 300px; */
}
.news-card .new-card-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    padding: 1rem;
}
.news-card .news-card-tags {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
}
.news-card .news-card-tags div {
    background-color: 	#00629B;
    color:#fff;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
}
.news-card .news-card-tags div i {
    margin-right: 5px;
}
.news-card .news-card-title {
    font-size: clamp(1.4rem, 1.7vw, 5rem);
    position: relative;
    width: fit-content;
}
.news-card .news-card-title::before {
    content:"";
    position: absolute;
    left: 0;
    width: 50%;
    background-color: #00629B;
    height: 5px;
    border-radius: 4px;
    bottom: -5px;
}
.news-card-time {
    display: flex;
    align-items: center;
}
.news-card-time svg{
    width: 21px;
    margin-right: 3px;
}
.news-card .news-card-description {
/*    font-size: 0.9rem;*/
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient:vertical;
    overflow: hidden;
    line-height: 1.2rem;
}
.news-card-button {
    padding: 0.3rem 0.5rem;
    text-decoration: none;
    background-color: #fff;
    color: var(--ieee-blue);
    width: fit-content;
    border: 2px solid var(--ieee-blue);
    border-radius: 4px;
}
.news-card-button:hover {
    color: #fff;
    background-color: var(--ieee-blue);
    transition: 150ms ease-in;
}

@media screen and (max-width:1520px) {
    .news-card {
        width: 90%;
    }
    .news-card .new-card-details {
        padding: 0.5rem;
    }
    .news-card img {
        width: 30%;
    }
}

@media screen and (max-width:1065px) {
    .news-card {
        width: 95%;
    }
    .news-card img {
        width: 40%;
    }
}

@media screen and (max-width: 700px) {
    .news-card {
        width: 70%;
        flex-direction: column;
    }
    .news-card img {
        width: 100%;
        max-width: none;
        margin-bottom: 1rem;
        border-bottom: 1px solid #ccc;
    }
}

@media screen and (max-width: 600px) {
    .news-card {
        width: 90%;
        flex-direction: column;
    }
}