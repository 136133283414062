.pagination {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
  .pagination li {
    display: inline;
    text-align: center;
  }
  .pagination a {
    float: left;
    display: block;
    font-size: 0.8rem;
    text-decoration: none;
    padding: 5px 12px;
    color: #fff;
    margin-left: -1px;
    border: 1px solid transparent;
    line-height: 1.5;
    cursor: pointer;
  }
  .pagination a.active {
    cursor: default;
  }
  .pagination a:active {
    outline: 2px solid transparent;
    /* outline: none; */
  }
  
  .modal-1 li:first-child a {
    -moz-border-radius: 6px 0 0 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px 0 0 6px;
  }
  .modal-1 li:last-child a {
    -moz-border-radius: 0 6px 6px 0;
    -webkit-border-radius: 0;
    border-radius: 0 6px 6px 0;
  }
  .modal-1 a {
    border-color: #ddd;
    /* color: #4285F4; */
    color: #00629B;
    background: #fff;
  }
  .modal-1 a:hover {
    background: #eee;
  }
  .modal-1 a.active, .modal-1 a:active {
    /* border-color: #4285F4; */
    border-color: #00629B;
    /* background: #4285F4; */
    background: #00629B;
    color: #fff;
  }
  