.social-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap:8px;
    margin-top:1rem;
    /* justify-content: center; */
    /* gap: 8px; */
  }
  
  .social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 50px;
    height: 50px;
    text-decoration: none;
    border-radius: 100%;
    background: #fff;
    text-align: center;
  }
  .social-button::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.3s;
  }
  .social-button:focus, .social-button:hover {
    color: #fff;
  }
  .social-button:focus::after, .social-button:hover::after {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin-left: calc(-50% - 1px);
  }
  .social-button i,
  .social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
    font-size: 2rem;
  }
  .social-button i {
    font-size: 25.6px;
  }
  .social-button svg {
    /* height: 40%; */
    /* width: 40%;     */
  }
  .social-button--mail {
    color: #0072c6;
  }
  .social-button--mail::after {
    background: #0072c6;
  }
  .social-button--facebook {
    color: #3b5999;
  }
  .social-button--facebook::after {
    background: #3b5999;
  }
  .social-button--instagram {
    color: #e4405f;
  }
  .social-button--instagram::after {
    background: #e4405f;
  }
  .social-button--linkedin {
    color: #0077b5;
  }
  .social-button--linkedin::after {
    background: #0077b5;
  }
  .social-button--email {
    color: #eec900;
  }
  .social-button--email::after {
    background: #eec900;
  }
  .social-button--location {
    color: #ea4335;
  }
  .social-button--location::after {
    background: #ea4335;
  }