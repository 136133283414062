.about-section{
    /* width: max(90%,330px); */
    min-width: 330px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 5rem;
}
.about-container {
    font-size: clamp(1.2rem, 1em + 0.53vw, 1.5rem);
    line-height: calc(1ex / 0.32);
    font-weight: 550;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.about-container .about-container-title {
    font-size: clamp(2rem, 2vw, 7rem);
    margin-block: 2rem;
    color: 	#00629B;
    line-height: calc(1ex / 0.42);
}

.about-container img {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 3rem;
    width: 300px;
    /* width: max(10%,250px); */
}
.about-container ul {
    margin-block:0.5rem;
}
.about-container ul li {
    margin-top:0.5rem;
    padding: 10px 20px;
    border: 1px solid #eee;
    background-color: var(--ieee-blue);
    color: #fff;
    box-shadow: 4px 4px 	#FFA300;
    /* list-style-type: "\2B50"; */
    list-style: none;
    width: min(600px, 100%);
    border-radius: 8px;
}
.about-container ul li:hover {
    transition: all .4s ease-in-out;
    box-shadow: 2px 2px #FFA300;
    transform: translateX(4px) translateY(4px);
  }