.join-ieee-page-container {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    width: 100%;
}
.join-ieee-container {
    margin: 2rem auto 0 auto;
    /* width: max(80%,340px); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #ccc;
    padding:1.5rem;
    border-radius: 8px;
    outline:2px solid transparent;
    width: 100%;
}
.join-ieee-container h2 {
    font-size: clamp(2.2rem, 3vw, 5rem);
    /* text-align: center; */
    padding-top:2rem;
}
.join-ieee-body p {
    margin-top:2rem;
}
.join-ieee {
    /* background-color: rgba(255, 163, 0, .2); */
}
.join-ieee-body {
    font-size: clamp(1.2rem, 1.1vw, 1.6rem);
    margin-top:2rem;
    padding:0.5rem;
    margin-bottom: 2rem;
    /* font-weight: 600; */
    line-height: clamp(1.3rem, 1.5vw, 1.7rem);
} 
.join-ieee-body .list-title {
    margin-top:2rem;
    font-size: clamp(1.7rem, 1.5vw, 3rem);
}
.join-ieee-body ul {
    margin-block:2rem;
}
.join-ieee-body ul li {
    margin-top:1rem;
    padding: 10px 20px;
    border: 1px solid #eee;
    background-color: var(--ieee-blue);
    color: #fff;
    box-shadow: 4px 4px 	#FFA300;
    /* list-style-type: "\2B50"; */
    list-style: none;
    border-radius: 8px;
}
.join-ieee-body ul li:hover {
    transition: all .4s ease-in-out;
    box-shadow: 2px 2px #FFA300;
    transform: translateX(4px) translateY(4px);
  }
.join-ieee-button {
    padding:0.5rem 0.7rem;
    background-color: var(--ieee-blue);
    border-radius: 4px;
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-decoration: none;
    border: 1px solid #ccc;
    outline: 2px solid transparent;
}
.join-ieee-button:hover {
    background-color: #fff;
    color: var(--ieee-blue);
    transition: 150ms ease-in;
}
.join-ieee-button.reversed {
    background-color: #fff;
    color: var(--ieee-blue);
}
.join-ieee-button.reversed:hover {
    color: #fff;
    background-color: var(--ieee-blue);
}



@media screen and (max-width:1000px) {
    .join-ieee-page-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}