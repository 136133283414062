
.hero {
  display: flex;
  flex-flow: column wrap;
  display: grid;
  grid-template-areas: "nav" "main";
  grid-template-columns: 1fr;
  grid-template-rows: 5rem calc(100vh - 5rem);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  font-family: var(--spartan);
  animation: fadeIn 0.25s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.hero::before {
  /* background-image: var(--bg-1); */
  /* background-image: url(../assets/images/landing/background_hero.jpg); */
  background-image: var(--bg-1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: var(--full-h);

  /* filter: brightness(0.25); */
  filter:brightness(0.35);
  position: absolute;
  inset: 0;
  width: var(--full-w);

/* Create the parallax scrolling effect */
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
  
  .hero img {
    max-width: var(--full-w);
    display: block;
  }
  
  .hero ul {
    gap: var(--default-padding);
  }
  
  .hero h1 {
    -webkit-background-clip: text;
    -webkit-box-decoration-break: clone;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: var(--bg-2);
    -webkit-text-stroke: 1.7px #000;
    /* background-position: 60% 41.5%; */
    background-position: center 70%;
    /* background-size: 150%; */
    /* background-blend-mode: luminosity; */
        /* background-position: center 75%; */
    background-repeat:no-repeat;
    background-size: 100%;
    display: inline;
    font-size: var(--h1-clamp);
    font-variation-settings: "wght" 900;
    order: 2;
    overflow: hidden;
    user-select: none;
  }
  
  .hero h1,
  .hero h2 {
    margin: 0 auto;
  }
  
  .hero h1 +   h2 {
    border-bottom: var(--gap) solid var(--pale-blue);
    order: 3;
  }
  
  .hero h2 {
    align-items: center;
    display: grid;
    font-size: var(--h2-clamp);
    /* font-size: calc(var(--default-font-size) * 1.25); */
    font-variation-settings: "wght" 750;
    grid-gap: calc(var(--default-padding) * 1.25);
    grid-template-columns: calc(var(--default-padding) * 2) auto calc(var(--default-padding) * 2);
    order: 1;
    width: fit-content;
  }
  
  .hero h2::before, .hero h2::after {
    content: "";
    border-top: var(--gap) solid var(--pale-blue);
  }
  
  .hero h2,
  .hero h3 {
    color: var(--white);
    position: relative;
  }

  .hero h3 {
    align-items: baseline;
    display: flex;
    font-size: calc(var(--default-font-size) * 1.5);
    font-variation-settings: "wght" 600;
    padding: calc(var(--default-padding) / 2);
    z-index: 2;
  }

  .landing-hero,
  .landing-context-wrapper {
    width: var(--full-w);
  }
  
  
  .landing-hero {
    grid-area: main;
    z-index: var(--z-30);
  }
  
  .landing-context-wrapper {
    display: flex;
    flex-flow: column nowrap;
    height: var(--full-h);
  }
  
  .landing-context-wrapper .headings {
    display: flex;
    flex-flow: column wrap;
    row-gap: calc(var(--default-padding) * 1.25);
    margin: auto 0;
  }
  
  .hero svg {
    fill: #fff;
    mix-blend-mode: exclusion;
    width: 3rem;
  } 
  .glass {
    background-color: rgba(0, 0, 0, 0.2);
    /* backdrop-filter: blur(var(--default-padding)); */
    backdrop-filter: blur(2px);
  }

  .banner {
    display: flex;
    flex-flow: column wrap;
  }
  .banner .banner-wrapper {
    align-items:baseline;
    display: grid;
    grid-auto-flow: column;
    grid-column: 1;
    grid-template-columns: repeat(auto-fill, minmax(min(12.5rem, 100%), 1));
  }
  .banner .banner-wrapper .box {
    align-items: center;
    box-shadow: inset var(--gap) 0 0 0 var(--dark-blue);
    display: flex;
    flex-flow: column wrap;
    padding: var(--default-padding) calc(var(--default-padding) / 2);
    transition: fill var(--duration) ease-in-out;
  }
  .banner .banner-wrapper .box:nth-of-type(2) {
    box-shadow: inset var(--gap) 0 0 0 var(--blue);
  }
  .banner .banner-wrapper .box:nth-of-type(3) {
    box-shadow: inset var(--gap) 0 0 0 var(--light-blue);
  }
  .banner .banner-wrapper .box:hover svg, .banner .banner-wrapper .box:focus svg {
    fill: var(--pale-blue);
  }
  .banner .banner-wrapper p {
    color: var(--white);
    font-size: calc(var(--default-font-size));
    font-variation-settings: "wght" 700;
  }
  .banner .banner-wrapper svg {
    mix-blend-mode: exclusion;
    width: 2.5rem;
  }
  
  .mountain-list {
    box-shadow: inset 0 var(--gap) 0 0 var(--white);
    display: flex;
    flex-flow: column wrap;
    mix-blend-mode: overlay;
    padding: var(--default-padding) var(--default-padding) 0 var(--default-padding);
  }
  .mountain-list ul {
    display: grid;
    gap: var(--gap);
    grid-auto-flow: column;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    place-items: center;
    scroll-snap-type: x mandatory;
    width: 100vw;
  }
  .mountain-list li {
    aspect-ratio: 16/9;
    padding: 0 calc(var(--default-padding) / 2);
    scroll-snap-align: center;
  }
  .mountain-list li:hover img, .mountain-list li:focus img {
    filter: grayscale(0);
  }
  .mountain-list img {
    border-radius: 1rem;
    filter: grayscale(1);
    height: 10rem;
    max-width: none;
    object-fit: contain;
  }
  .mountain-lines {
    height: var(--full-h);
    left: 0;
    mix-blend-mode: luminosity;
    object-fit: cover;
    opacity: 0.5;
    position: absolute;
    top: calc(var(--default-padding) * 2);
    width: var(--full-w);
  }

  @media (prefers-reduced-motion: reduce) {
    /* CSS to disable motion goes here */
    .hero::before {
      background-attachment: unset;
    }
    .hero {
      animation: none;
    }
  }
  
  @media screen and (max-width:1050px) {

    .hero h2 {
      letter-spacing: 0!important;
    }
  }

  @media screen and (max-width: 991px) {
    /* start of large tablet styles */

    /* Create the parallax scrolling effect */
    .hero::before {
      background-attachment: unset;
    }

  }


  @media screen and (max-width:470px) {
    .hero h2::before, .hero h2::after {
      content: "";
      border-top: var(--gap) solid var(--pale-blue);
    }
  }
  @media only screen and (min-width: 760px) {
    .hero {
      overflow: hidden;
    }
    .hero h1 {
      filter: drop-shadow(calc(-1 * var(--default-padding)) var(--default-padding) calc(var(--default-padding) * 2) var(--black));
      -webkit-text-stroke: 3px #000;

    }
    .hero h2 {
      font-size: calc(var(--default-font-size) * 1.75);
      grid-template-columns: minmax(6rem, 1fr) auto minmax(6rem, 1fr);
      /* letter-spacing: calc(var(--default-padding) / 1.5); */
      letter-spacing: 0.5rem;
    }
    .hero h2:nth-of-type(2) {
      transform: translatey(-1.75rem);
    }
    .headings {
      margin: auto;
      row-gap: 0;
    }
    .banner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .banner .banner-wrapper {
      align-items: flex-end;
      grid-template-columns: repeat(3, minmax(12rem, max-content));
    }
    .mountain-list {
      margin-left: auto;
    }
    .mountain-list ul {
      max-width: 48rem;
    }
    .mountain-list img {
      height: 15rem;
      overflow: hidden;
    }
    .mountain-lines {
      opacity: 0.33;
    }
  }
  /* ----------------------------------------------
    Disable Animation if that is user settings
  * ---------------------------------------------- */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation: none;
      transition: none;
    }
  }