.error-container {
    position: absolute;
    top:45%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}
.error-container h1 {
    font-size: clamp(2.1rem, 3vw, 5rem);
    margin-bottom: 1.5rem;
    color: #fff;
    background-color: var(--ieee-blue);
    width: fit-content;
    border-radius: 8px;
    margin: 0 auto 1.5rem auto;
    padding:0.2rem 0.5rem;
}
.error-container img {
    width: max(20%, 340px);
    height: auto;
    margin-block: 1rem;
    margin-bottom:2rem;
}
.error-container a {
    background-color: #00629B;
    border: 1px solid #eee;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #fff;
    /* font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
    font-size: .875rem;
    font-weight: 650;
    line-height: 1.25rem;
    padding: .7rem 1rem;
    text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    outline: 2px solid transparent;
  }
  
  .error-container a:hover {
    /* background-color: rgb(249,250,251); */
      color: #00629B;
      /* color: 	#981D97; */
      background-color: #fff;
      transition: 150ms ease-in;
  }
  
  .error-container a:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  
  .error-container a:focus-visible {
    box-shadow: none;
  }