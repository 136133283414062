.contact-wrapper {
    display: flex;
    /* flex-direction: column; */
    flex-direction: row;
    flex-wrap: wrap-reverse;
    column-gap: 4rem;
    row-gap: 2rem;
    justify-content: flex-start;
    margin-top: 4rem;
}
.contact-form {
    
    width: min(100%, 850px);
}
.contact-form span.required-input {
    color:	#BA0C2F;
    font-size: 22px;
}
.contact-form .contact-note {
    color:#75787B;
}
.inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.contact-form .input {
    margin-bottom: 1.5rem;
}
.contact-form input {
    -webkit-appearance: none;
    padding: 7px 40px 7px 12px;
    width: 100%;
    border: 1px solid #e8eaed;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
    margin-bottom: 2px;
}
.contact-form input:focus {
    outline: none;
    border-color: #07f;
    box-shadow: 0 0 0 2px rgba(0,119,255,0.2);
}
.contact-form input::placeholder {
    font-size: 0.9rem;
    /* color: #75787B; */
    color: var(--light-blue);
    /* font-family: 'Roboto', sans-serif; */
    font-family: var(--normal-font);
}
.contact-form textarea {
    -webkit-appearance: none;
    border-radius: 5px;
    box-shadow: 0 1px 3px -2px #9098a9;
    background: #fff;
    resize: none;
    border: 1px solid #e8eaed;
    padding: 7px 40px 7px 12px;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
    width: 100%;
}
.contact-form textarea:focus {
    outline: none;
    border-color: #07f;
    /* border-color: hotpink; */
    box-shadow: 0 0 0 2px rgba(0,119,255,0.2);
}
.contact-form textarea::placeholder {
    font-size: 0.9rem;
    color: var(--light-blue);
    font-family: var(--normal-font);
}

/* .contact-form button {
    border: none;
    outline: 1px solid transparent;
    background: linear-gradient(to right, hotpink, #00629B);
    color: #fff;
    padding: 0.5rem;
    font-size: 1.125rem;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
} */

/* CSS */
.contact-form button {
  background-color: #00629B;
  border: 1px solid #eee;
  border-radius: .5rem;
  box-sizing: border-box;
  color: #fff;
  /* font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
  font-size: .875rem;
  font-weight: 650;
  line-height: 1.25rem;
  padding: .7rem 1rem;
  text-decoration: none #D1D5DB solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  outline: 2px solid transparent;
  display: flex;
  align-items: center;
}
.contact-form button svg {
    margin-left: 4px;
    width: 1.3rem;
}

.contact-form button:hover {
  /* background-color: rgb(249,250,251); */
    color: #00629B;
    /* color: 	#981D97; */
    background-color: #fff;
    transition: 150ms ease-in;
}

.contact-form button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.contact-form button:focus-visible {
  box-shadow: none;
}
.contact-form .message {
    margin-bottom:0.5rem;
}
.contact-form .success-message {
    color:#00843D;
}
.contact-form .error-message {
    color: #BA0C2F;
}
.contact-form input[type="checkbox"] {
  /* Set the width and height of the checkbox */
  width: 18px;
  height: 18px;

  /* Remove the default border */
  border: none;

  /* Set the background color of the checkbox */
  background-color: #fff;

  /* Set the margin and padding of the checkbox */
  margin: 0;
  padding: 0;

  /* Set the appearance of the checkbox to none to hide the default checkbox */
  appearance: none;

  /* Add a border and border-radius to the checkbox */
  border: 1px solid #ccc;
  border-radius: 3px;

  /* Add some padding to the checkbox */
  padding: 3px;
}
.contact-form input[type="checkbox"]:checked {
    /* Set the background color and border color of the checkbox when checked */
    background-color: #007bff;
    border-color: #007bff;
}