.events-filters {
    margin-top:2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;
}
.events-container {
    margin-block: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap:4rem;
    row-gap:3rem;
    align-items: center;
}
.event-card {
    width: 15%;
    min-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    margin: 0 auto;
}
.event-card-time {
    position: absolute;
    bottom: -1px;
    left: 0;
    background-color: 	#E87722;
    color: #fff;
    padding: 0.5rem;
}
.reminder-button {
    position: absolute;
    bottom:-20px;
    right: 10px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 2px solid #E87722;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    flex-wrap:wrap;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;

}
button.reminder-button i {
    font-size: 35px;
    height: auto;
}
button.reminder-button i:hover {
    color:var(--ieee-blue);
}
button.reminder-button:hover {
    background-color: #eee;
}
button.reminder-button:hover > i { /* if we are hovering over the button, but not the i tag */
    color:var(--ieee-blue);
}
.event-card-image {
    position: relative;
}
.event-card-image img {
    width: 100%;
    /* max-height: 150px; */
    height: 180px;
    object-fit: cover;
}
.event-card .event-card-details {
    padding: 0.2rem 0.5rem
}
.event-card .event-card-details .event-card-title {
    /* margin-top: 1.5rem; */
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    /* width: 90%; */
    line-height: 1.4rem;
    width: 100%;
    font-weight: 700;
    text-align: center;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient:vertical;
    overflow: hidden;


}
.event-card .seperator {
    width: 90%;
    margin: 0 auto;
    height: 4px;
    background-color: #00629B;
    margin-bottom: 0.9rem;
    border-radius: 8px;
}
.event-card-expired {
    font-weight: 650;
    font-size: 0.9rem;
    /* background-color: 	#BA0C2F; */
    background-color: 	#75787B;
    /* background-color: #E87722; */
    color:#fff;
    width: fit-content;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    margin-bottom: 5px;
    float: right;
}
.event-card-place, .event-card-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    /* font-size: 0.9rem; */
    margin-top:5px;
    font-weight: 700;
    margin-bottom: 5px;
}
.event-card-place {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient:vertical;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.event-card-place i {
    margin-right: 10px;
}
.event-card-place svg {
    width: 20px;
    /* margin-top:3px; */
    /* margin-right: 10px; */
}
.event-card-description {
    font-size: 0.9rem;
    font-weight: 700;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient:vertical;
    overflow: hidden;
    width: 100%;
    line-height: 1rem;
}
.event-card-buttons {
    margin-top:5px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    /* margin-left: 10px; */
    width: 100%;
    column-gap: 1rem;
}
/* .event-card-buttons a, .event-card-buttons button {
    padding: 0.3rem 0.5rem;
    width: 100%;
    text-transform: uppercase;
    font-family: var(--spartan);
    font-weight: 600;
    background-color: #fff;
    border:none;
    cursor: pointer;
    text-align: center;
    font-size: 0.9rem;
    text-decoration: none;
    color: #000;
}

.event-card-buttons a:hover, .event-card-buttons button:hover {
    color: #00629B;
    font-weight: 650;
    letter-spacing: 0.5px;
    transition: 0.1s ease-in;
}
.event-card-buttons a.border-gradient,.event-card-buttons button.border-gradient {
    border: 1px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-radius: 50px;
    border-image-source: linear-gradient(to right, #00629B, #BA0C2F);
} */


/* CSS */
.event-card-buttons a, .event-card-buttons button {
    display: flex;
    justify-content: center;
    
    outline: 2px solid transparent;
    cursor: pointer;
    border-radius: 8px;
    font-size: 0.9rem;
    line-height: 16px;
    padding: 0.5rem 0.7rem;
    /* height: 35px; */
    /* min-width: 50px; */
    /* min-height: 20px; */
    align-items: center;
    text-decoration: none;
    border: 1px solid #ccc;
    color: #fff;
    background-color: #00629B;
    transition: background-color 250ms ease,color 250ms ease;
    font-family: var(--spartan);
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.event-card-buttons a:hover, .event-card-buttons button:hover {
    /* background-color: rgb(71, 82, 196); */
    background-color: #fff;
    /* color: rgb(71, 82, 196); */
    color: #00629B;
}

.events-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.events-not-found img {
    width: max(20%, 340px);
    margin-bottom: 1.5rem;
}
.events-not-found a {
    text-decoration: none;
    background-color: #00629B;
    color: #fff;
    padding: 0.5rem;
    border-radius: 8px;
    width: fit-content;
    outline: 2px solid transparent;
    margin-top:0.5rem;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border: 1px solid #ccc;
    transition: background-color 250ms ease,color 250ms ease;
}
.events-not-found a:hover {
    background-color: #fff;
    color: #00629B;
}