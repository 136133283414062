.search-bar {
    display: flex;
}
.events-search-bar input {
    -webkit-appearance: none;
    padding: 7px 40px 7px 12px;
    width: 100%;
    border: 1px solid #e8eaed;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 1px 3px -2px #9098a9;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
}
.events-search-bar input:focus {
    outline: none;
    border-color: #07f;
    box-shadow: 0 0 0 2px rgba(0,119,255,0.2);
}
.events-search-bar button {
    background-color: transparent;
    border: 1px solid #e8eaed;
    padding: 5px 8px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 550;
    font-size: 1rem;
    transition: 150ms ease-in;
}
.events-search-bar button:hover {
    border-color: #07f;
}
.events-search-bar button img {
    width: 20px;
}
@media (forced-colors: active) {
    .events-search-bar button img {
        fill : #ffff;
    }
}