.elementor-widget-container {  -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    --bdt-breakpoint-s: 640px;
    --bdt-breakpoint-m: 960px;
    --bdt-breakpoint-l: 1200px;
    --bdt-breakpoint-xl: 1600px;
    --bdt-leader-fill-content: .;
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px;
    --page-title-display: block;
    --auxin-featured-color-1: #059775;
    --auxin-featured-color-2: #202020;
    --auxin-featured-color-3: #f9f9f9;
    --auxin-featured-color-4: #393939;
    --auxin-featured-color-5: #fff;
    --wp--preset--color--black: #000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #fff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,#9b51e0 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,#7adcb4 0%,#00d082 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,#cf2e2e 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,#eee 0%,#a9b8c3 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,#4aeadc 0%,#9778d1 20%,#cf2aba 40%,#ee2c82 60%,#fb6962 80%,#fef84c 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,#ffceec 0%,#9896f0 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,#fecda5 0%,#fe2d2d 50%,#6b003e 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,#ffcb70 0%,#c751c0 50%,#4158d0 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg,#fff5cb 0%,#b6e3d4 50%,#33a7b5 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg,#caf880 0%,#71ce7e 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg,#020381 0%,#2874fc 100%);
    --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
    --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
    --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
    --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
    --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
    --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
    --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
    --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: .44rem;
    --wp--preset--spacing--30: .67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    font-size: 100%;
    line-height: 1.5;
    font-style: normal;
    color: #444;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: Ubuntu;
    /* --e-global-color-primary: #7fb942;
    --e-global-color-secondary: #31c0c5;
    --e-global-color-text: #7a7a7a;
    --e-global-color-accent: #61ce70;
    --e-global-color-34462eb6: #127438;
    --e-global-color-39b235bd: #4054b2;
    --e-global-color-6d3445e6: #23a455;
    --e-global-color-473512dc: #000;
    --e-global-color-7792b880: #fff;
    --e-global-color-b4cb7ac: #447341; */

     --e-global-color-primary: #00629B;
    --e-global-color-secondary: 	#BA0C2F;
    --e-global-color-text: 	#981D97;
    /* --e-global-color-accent: #61ce70;
    --e-global-color-34462eb6: #127438;
    --e-global-color-39b235bd: #4054b2;
    --e-global-color-6d3445e6: #23a455;
    --e-global-color-473512dc: #000;
    --e-global-color-7792b880: #fff;
    --e-global-color-b4cb7ac: #447341; */

    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
    word-wrap: break-word;
    hyphens: manual;
    box-sizing: border-box;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;
    margin: -11px 0 0;

  }
  .elementor-widget-container.right {
    float:right;
    margin: 40px 0 0;

  }
  
  .aux-bus2-title-divider {  -webkit-tap-highlight-color: transparent;
    margin-top:10px;
  
    -webkit-text-size-adjust: 100%;
    --bdt-breakpoint-s: 640px;
    --bdt-breakpoint-m: 960px;
    --bdt-breakpoint-l: 1200px;
    --bdt-breakpoint-xl: 1600px;
    --bdt-leader-fill-content: .;
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px;
    --page-title-display: block;
    --auxin-featured-color-1: #059775;
    --auxin-featured-color-2: #202020;
    --auxin-featured-color-3: #f9f9f9;
    --auxin-featured-color-4: #393939;
    --auxin-featured-color-5: #fff;
    --wp--preset--color--black: #000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #fff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,#9b51e0 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,#7adcb4 0%,#00d082 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,#cf2e2e 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,#eee 0%,#a9b8c3 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,#4aeadc 0%,#9778d1 20%,#cf2aba 40%,#ee2c82 60%,#fb6962 80%,#fef84c 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,#ffceec 0%,#9896f0 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,#fecda5 0%,#fe2d2d 50%,#6b003e 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,#ffcb70 0%,#c751c0 50%,#4158d0 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg,#fff5cb 0%,#b6e3d4 50%,#33a7b5 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg,#caf880 0%,#71ce7e 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg,#020381 0%,#2874fc 100%);
    --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
    --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
    --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
    --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
    --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
    --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
    --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
    --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: .44rem;
    --wp--preset--spacing--30: .67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    font-size: 100%;
    line-height: 1.5;
    font-style: normal;
    color: #444;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: Ubuntu;
    --e-global-color-primary: #7fb942;
    --e-global-color-secondary: #31c0c5;
    --e-global-color-text: #7a7a7a;
    --e-global-color-accent: #61ce70;
    --e-global-color-34462eb6: #127438;
    --e-global-color-39b235bd: #4054b2;
    --e-global-color-6d3445e6: #23a455;
    --e-global-color-473512dc: #000;
    --e-global-color-7792b880: #fff;
    --e-global-color-b4cb7ac: #447341;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
    word-wrap: break-word;
    hyphens: manual;
    box-sizing: border-box;
    position: relative;
    height: 5px;
  }
  
  .elementor-widget-container:before, .elementor-widget-container:after, .elementor-container:before, .elementor-container:after {
      content: '';
  }
  
  .aux-bus2-title-divider:before {
    left: 0;
    width: 9px;
    opacity: .9;
  }
   .aux-bus2-title-divider:before, .aux-bus2-title-divider:after {
    content: "";
    position: absolute;
    border-radius: 20px;
    top: 0;
    height: 5px;
    background-image: -webkit-linear-gradient(left,#7fb942 0%,#31c0c5 100%);
    background-image: -o-linear-gradient(left,#7fb942 0%,#31c0c5 100%);
    background-image: linear-gradient(to right,#7fb942 0%,#31c0c5 100%);
    background-repeat: repeat-x;
  }
  
   .aux-bus2-title-divider:after {
    left: 15px;
    /* left: 0px; */
    /* width: 48px; */
    width: 60px;
  }
  .aux-bus2-title-divider:before, .aux-bus2-title-divider:after {
    content: "";
    position: absolute;
    border-radius: 20px;
    top: 0;
    height: 5px;
    /* margin-left: 1rem; */
    background-image: -webkit-linear-gradient(left,#7fb942 0%,#31c0c5 100%);
    background-image: -o-linear-gradient(left,#7fb942 0%,#31c0c5 100%);
    background-image: linear-gradient(to right,#7fb942 0%,#31c0c5 100%);
    background-repeat: repeat-x;
  }
  
  .elementor-widget-container:before, .elementor-widget-container:after, .elementor-section .elementor-container:before, .elementor-section .elementor-container:after {
    content: '';
  }